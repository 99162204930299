import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError, Observable, switchMap, throwError } from 'rxjs'
import { AuthService } from '@core/services/api/auth.service'

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.cloneRequest(request)

    // run request and check for 401 error (Unauthorized)
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('token') &&
          error.status === 401
        ) {
          return this.handle401Error(request, next)
        }

        return throwError(() => error)
      })
    )
  }

  private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
    // Add authentication token to the request headers
    const token: string = this.authService.getToken()

    if (token && !request.url.includes('token')) {
      let headers = request.headers
        .set('Authorization', `Bearer ${token}`);

      return request.clone({ headers });
    }

    return request
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      if (!this.authService.hasRefreshToken())
        return throwError(() => { 'Invalid RefreshToken' })

      this.isRefreshing = true

      return this.authService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false

          return next.handle(this.cloneRequest(request))
        }),
        catchError((error) => {
          this.isRefreshing = false

          if (error.status === '401' || error.status === '403') {
            this.authService.logout()
          }

          return throwError(() => error)
        })
      )
    }

    return next.handle(request)
  }
}
