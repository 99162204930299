<div class="layout-wrapper">
	<app-topbar></app-topbar>

	<app-main-menu />

	<div class="layout-main-container">
		<p-toast position="top-center" [preventOpenDuplicates]="true" [life]="4000"></p-toast>

		<div class="layout-content-wrapper">
			<div class="layout-main">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
