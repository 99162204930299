<div class="layout-topbar">
	<p-button (onClick)="toggleSidebar()" icon="pi pi-bars" [text]="true" styleClass="h-1rem w-3rem bars.button"></p-button>

  <a class="layout-topbar-logo" routerLink="/">
    <span>Backoffice</span>
  </a>

  <div class="layout-topbar-search">
    <tb-search />
  </div>
</div>
