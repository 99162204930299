import { Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { BehaviorSubject, Subscription } from 'rxjs'
import { ConfirmationService } from 'primeng/api'
import { CacheStorageService } from '@core/services/cache-storage.service'

@Injectable({ providedIn: 'root' })
export class UpdateService {

	public newVersionAvailable$: BehaviorSubject<boolean>
	private subscription!: Subscription

	constructor(
		private swUpdate: SwUpdate,
		private confirmationService: ConfirmationService,
		private cacheService: CacheStorageService,
	) {
		this.newVersionAvailable$ = new BehaviorSubject<boolean>(false)
	}

	public init(): void {
		this.subscription?.unsubscribe()

		if (!this.swUpdate.isEnabled) {
			return
		}

		this.subscription = this.swUpdate.versionUpdates.subscribe(evt => {
			switch (evt.type) {
				case 'VERSION_DETECTED':
					console.log(`Downloading new app version: ${evt.version.hash}`)
					break
				case 'VERSION_READY':
					console.log(`Current app version: ${evt.currentVersion.hash}`)
					console.log(`New app version ready for use: ${evt.latestVersion.hash}`)
					this.newVersionAvailable$.next(true)
					this.showConfirmation()
					break
				case 'VERSION_INSTALLATION_FAILED':
					console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`)
					break
			}
		})
	}

	private showConfirmation(): void {
		this.confirmationService.confirm({
			message: 'Bitte lade die Seite neu',
			header: 'Update vorhanden',
			icon: 'pi pi-info-circle',
			acceptIcon: 'none',
			rejectIcon: 'none',
			rejectButtonStyleClass: 'p-button-text',
			acceptLabel: 'Neuladen',
			rejectLabel: 'Abbrechen',
			accept: (): void => {
				this.cacheService.clearAll()
				window.location.replace(window.location.href)
			},
		})
	}

}
