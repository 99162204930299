import { NgModule } from '@angular/core'
import { Routes, RouterModule, TitleStrategy } from '@angular/router'
import { PageNotFoundComponent } from '@app/components/uiux/not-found/page-not-found.component'
import { PageTitleStrategy } from '@core/services/page-title-strategy'
import { AppLayoutComponent } from '@app/layout/app-layout.component'
import { canActivate } from '@core/services/api/auth.guard'

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [ canActivate ],
    canActivateChild: [ canActivate ], // gets called for each child in tree
    children: [
      { path: 'controlling', loadChildren: () => import('./modules/controlling/controlling.module').then(m => m.ControllingModule)},
      { path: 'finance', loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule)},
      { path: 'goods', loadChildren: () => import('./modules/goods/goods.module').then(m => m.GoodsModule) },
      { path: 'sales', loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule) },
      { path: 'purchase', loadChildren: () => import('./modules/purchase/purchase.module').then(m => m.PurchaseModule) },
      { path: 'supervision', loadChildren: () => import('./modules/supervision/supervision.module').then(m => m.SupervisionModule) },
      { path: 'logistics', loadChildren: () => import('./modules/logistics/logistics.module').then(m => m.LogisticsModule) },
      { path: 'user', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
    ]
  },
  { path: 'auth', loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule )},
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: TitleStrategy, useClass: PageTitleStrategy },
  ]
})
export class AppRoutingModule {}
