import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, Router } from '@angular/router'
import { AuthService } from './auth.service'
import { MessageService } from 'primeng/api'

export const canActivate: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthGuard).canActivate(route)
  };

@Injectable({ providedIn: 'root' })
export class AuthGuard {

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    // check permission of current route
    const permission = route.data?.['permission']

    if (typeof permission !== 'undefined' && permission !== null && permission > 0) {
      const result = this.authService.isLoggedIn() && this.authService.hasPermission(permission)

      if (!result) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Keine ausreichende Berechtigung',
        })
      }

      return result
    }

    // navigate to login if user is not logged in
    const result = this.authService.isLoggedIn()

    if (!result) {
      this.router.navigate([ '/auth/login' ])
    }

    return result
  }

}
