import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AuthInterceptor } from '@core/services/api/auth-interceptor'
import { ConfirmationService, MessageService } from 'primeng/api'
import { ToastModule } from 'primeng/toast'
import { AuthGuard } from '@core/services/api/auth.guard'
import { LoadingInterceptor } from '@core/services/api/loading-interceptor'
import { LoadingComponent } from '@app/components/uiux/loading/loading.component'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { BulkService } from '@core/data-services/shared/bulk.service'
import { DialogService } from 'primeng/dynamicdialog'
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router'
import { SentryErrorHandlerFactory } from '@core/services/sentry-error-handler-factory';
import { ServiceWorkerModule } from '@angular/service-worker'

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    LoadingComponent,
    ConfirmDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    HttpClientModule,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    MessageService,
    AuthGuard,
    ConfirmationService,
    BulkService,
    DialogService,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: SentryErrorHandlerFactory
    },
  ]
})
export class AppModule {}