<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="flex flex-column align-items-center justify-content-center py-5">
    <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
      <div class="w-full surface-card py-8 px-5 text-center" style="border-radius:53px">
        <i class="pi pi-bolt" style=" font-size: 4rem; color: #eee;"></i>
        <h3>404 - Seite nicht gefunden</h3>
        <p>Die aufgerufene Seite konnte nicht gefunden werden.</p>
      </div>
    </div>
  </div>
</div>
