import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { AppTopBarComponent } from '@app/layout/app-top-bar.component'
import { ToastModule } from 'primeng/toast'
import { MainMenuComponent } from '@app/components/uiux/main-menu/main-menu.component'

@Component({
	selector: 'app-layout',
	standalone: true,
	templateUrl: './app-layout.component.html',
	imports: [
		AppTopBarComponent,
		MainMenuComponent,
		ToastModule,
		RouterOutlet,
	],
})
export class AppLayoutComponent {
}
