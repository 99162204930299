import { inject, isDevMode } from '@angular/core'
import { AuthService } from '@core/services/api/auth.service'
import * as Sentry from '@sentry/angular'

export const SentryErrorHandlerFactory = () => {
  const authService = inject(AuthService)
  const user = authService.getUser()

  Sentry.setUser({
    email: user?.email || '',
    username: `${user?.firstName} ${user?.lastName}`
  })
  if(isDevMode()) return { handleError: console.error };

  return Sentry.createErrorHandler({
    showDialog: true,
    dialogOptions: {
      user: {
        name: user?.firstName || '',
        email: user?.email || '',
      }
    }
  })
}