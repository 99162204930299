<div class="flex justify-content-right">
	<p-sidebar [(visible)]="sidebarVisible" [closeOnEscape]="false" styleClass="p-sidebar-active" [modal]="false">
		<ng-template pTemplate="headless">
			<div class="flex flex-column h-full">
				<div class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
					<span class="inline-flex align-items-center gap-2">
						<a href="/" style="color: #ffffff">
							<span class="font-semibold text-2xl">Backoffice</span>
						</a>
					</span>

					<span>
							<p-button type="button" (click)="toggleLock(!sidebarLocked)" [icon]="sidebarLocked ? 'pi pi-lock' : 'pi pi-unlock'" styleClass="h-2rem w-2rem lock-button" [text]="true"></p-button>
							<p-button type="button" *ngIf="!sidebarLocked" (click)="toggleVisible(false)" icon="pi pi-times" styleClass="h-2rem w-2rem" [text]="true"></p-button>
					</span>
				</div>

				<div class="overflow-y-auto">
					<ul class="list-none p-3 m-0">
						<li *ngFor="let item of processedMenuItems">
							<a
								pRipple
								class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
								[ngClass]="{'active-menuitem': isActive(item)}"
								(click)="onMenuItemClick(item, $event)"
								[routerLink]="getRouterLink(item)"
							>
								<i [class]="item.icon"></i>
								<span class="ml-1 font-medium">{{ item.label }}</span>
								<p-badge
									[styleClass]="'badgeNumber'"
									severity="primary"
									[value]="unreadNotifications"
									class="pi ml-auto"
									*ngIf="item.notificationBadge && unreadNotifications > 0"
								/>
								<i *ngIf="item.subItems" class="pi ml-auto" [ngClass]="item.expanded ? 'pi-chevron-up' : 'pi-chevron-down'"></i>
							</a>

							<ul *ngIf="item.subItems && item.expanded" class="list-none py-0 pl-3 pr-0 m-0">
								<li *ngFor="let subItem of item.subItems">
									<a
										pRipple
										[routerLink]="getRouterLink(subItem)"
										class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
										[ngClass]="{'active-menuitem': isActive(subItem)}"
										(click)="onSubMenuItemClick(subItem, $event)"
									>
										<span class="font-medium">{{ subItem.label }}</span>
										<i *ngIf="subItem.subItems" class="pi ml-auto" [ngClass]="subItem.expanded ? 'pi-chevron-up' : 'pi-chevron-down'"></i>
									</a>

									<ul *ngIf="subItem.subItems && subItem.expanded" class="list-none py-0 pl-3 pr-0 m-0">
										<li *ngFor="let nestedSubItem of subItem.subItems">
											<a
												pRipple
												[routerLink]="nestedSubItem.routerLink"
												class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
												[ngClass]="{'active-menuitem': isActive(nestedSubItem)}"
												(click)="onSubMenuItemClick(nestedSubItem, $event)"
												routerLinkActive="active-menuitem"
											>
												<span class="font-medium">{{ nestedSubItem.label }}</span>
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</li>
					</ul>
				</div>

				<div class="mt-auto">
					<hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
					<div class="flex align-items-center justify-content-between px-4 py-3">
						<a pRipple class="flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
							<p-button icon="pi pi-fw pi-user" [text]="true"></p-button>
							<span class="font-bold">{{ userName }}</span>
						</a>

						<button class="p-link layout-topbar-button" (click)="doLogout()">
							<i class="pi pi-sign-out"></i>
						</button>

						<!--<button class="p-link layout-topbar-button">
							<i class="pi pi-calendar"></i>
							<span>Calendar</span>
						</button>

						<button class="p-link layout-topbar-button" [routerLink]="'/settings'">
							<i class="pi pi-cog"></i>
							<span>Settings</span>
						</button>-->
					</div>
				</div>
			</div>
		</ng-template>
	</p-sidebar>
</div>
