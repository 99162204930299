import { Component } from '@angular/core'
import { RouterLink } from '@angular/router'
import { SearchbarComponent } from '@app/components/uiux/searchbar/searchbar.component'
import { ButtonModule } from 'primeng/button'
import { SidebarService } from '@core/services/sidebar.service'

@Component({
	selector: 'app-topbar',
	standalone: true,
	templateUrl: 'app-top-bar.component.html',
	imports: [
		RouterLink,
		SearchbarComponent,
		ButtonModule,
	],
})
export class AppTopBarComponent {

	constructor(private sidebarService: SidebarService) {
	}

	protected toggleSidebar(): void {
		this.sidebarService.setOpened(true)
	}

}
